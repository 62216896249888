"use client";

import { Moon, Sun } from "lucide-react";
import { useTheme } from "next-themes";
import * as React from "react";
import { Button } from "@/shadcn/neoBrutalismbutton";
export function ThemeSwitcher() {
  const {
    setTheme,
    theme
  } = useTheme();
  return <>
      <Button size="icon" className="" onClick={() => setTheme(theme === "light" ? "dark" : "light")} data-sentry-element="Button" data-sentry-source-file="theme-switcher.tsx">
        <Sun className="hidden h-6 w-6 stroke-text dark:inline w500:h-4 w500:w-4" data-sentry-element="Sun" data-sentry-source-file="theme-switcher.tsx" />
        <Moon className="inline h-6 w-6 stroke-text dark:hidden w500:h-4 w500:w-4" data-sentry-element="Moon" data-sentry-source-file="theme-switcher.tsx" />
        <span className="sr-only">Toggle theme</span>
      </Button>
    </>;
}