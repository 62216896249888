"use client";

import { useTheme } from "next-themes";
import * as React from "react";
import { Button } from "@/shadcn/neoBrutalismbutton";
import { IconDiscord } from "~/client/components/DiscorIcon";
export function DiscordNeoButton() {
  return <a href="https://discord.com/invite/FY7XJq3WRc/" target="_blank" data-sentry-component="DiscordNeoButton" data-sentry-source-file="discord-button.tsx">
      <Button size="icon" data-sentry-element="Button" data-sentry-source-file="discord-button.tsx">
        <IconDiscord className="h-6 w-6 dark:inline w500:h-4 w500:w-4" data-sentry-element="IconDiscord" data-sentry-source-file="discord-button.tsx" />
        <span className="sr-only">Toggle theme</span>
      </Button>
    </a>;
}